<template>
  <div class="detail-container">
    <div class="detail">
      <div class="container">
        <div style="display: flex; align-items: center">
          <div style="margin-right: 20px">
            <el-image
              fit="contain"
              style="width: 120px; height: 120px"
              :src="jsonData.projectImg"
              :previewSrcList="[jsonData.projectImg]"
            ></el-image>
          </div>
          <div class="title">
            {{ jsonData.projectName }}
          </div>
        </div>

        <div class="project-type flex">
          <div style="margin-left: 50%">
            创建时间：{{ jsonData.publishTime }}
          </div>
          <div class="flex">
            <div><i class="el-icon-view"></i>71</div>
            <div><i class="el-icon-share"></i>分享</div>
          </div>
        </div>
        <div class="tile_boxs">
          <div class="titles">项目信息</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="left-top">
              <div>
                工程简述：<span>{{ jsonData.projectDec }}</span>
              </div>
              <div>
                施工地点：<span
                  >{{ jsonData.projectProvince }}{{ jsonData.projectCity
                  }}{{ jsonData.projectCountry
                  }}{{ jsonData.projectAddress }}</span
                >
              </div>
              <div>
                工程类型：<span>{{ jsonData.projectType }}</span>
              </div>
              <div>
                支付方式：<span>{{ payTypeCode }}</span>
              </div>
              <div>
                施工备注：<span>{{ jsonData.projectRemark }}</span>
              </div>
            </div>
            <div class="left-content">
              <div class="tile_box">
                <div class="title">设备需求</div>
              </div>
              <el-table
                :data="jsonData.details"
                border
                style="width: 100%"
                :header-cell-style="{
                  background: '#f4f4f4',
                  textAlign: 'center',
                }"
                :cell-style="{ textAlign: 'center' }"
              >
                <el-table-column prop="vehicleName" label="机械名称">
                </el-table-column>
                <el-table-column prop="vehicleModel" label="机械型号">
                </el-table-column>
                <el-table-column label="机械种类">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.firstLevelType }}/{{
                        scope.row.secondLevelType
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="leaseUnit" label="租赁方式">
                </el-table-column>
                <el-table-column prop="workTimeStart" label="进场时间">
                </el-table-column>
                <el-table-column prop="needNumber" label="租赁数量">
                </el-table-column>
                <el-table-column prop="vehicleOffer" label="租赁费用">
                </el-table-column>
              </el-table>
              <div class="btn">
                <el-button size="small" @click="dialogVisible = true"
                  >立即沟通</el-button
                >
                <el-button
                  size="small"
                  @click="toOrders"
                  v-if="userInfo.businessStatus == 2"
                  >立即接单</el-button
                >
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="right">
              <div class="title">项目评估</div>
              <div class="right-box">
                <div class="img">
                  <img src="../../assets/images/bg-4.png" alt="" />
                  <div>真实有效</div>
                </div>
                <div class="jindu">
                  <div>履约进度</div>
                  <el-progress
                    :percentage="jsonData.quantityPercent"
                  ></el-progress>
                </div>
                <div class="phone">
                  <div class="pt">项目咨询电话</div>
                  <div class="number">0000000000</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 聊天弹窗 -->
    <el-dialog
      title="在线客服"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <chart></chart>
    </el-dialog>
    <!-- 底部     -->
    <elFooter class="elFooter"></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'
import chart from '@/components/dialog/contact'
import { getById } from '@/api/car'
export default {
  data() {
    return {
      bg3: require('@/assets/images/service-bg-1.jpg'),
      dialogVisible: false,
      jsonData: {},
      userInfo: {},
    }
  },
  components: { chart, elFooter },
  computed: {
    pageName() {
      return this.$route.name
    },
    payTypeCode() {
      let code = this.jsonData.payTypeCode
      return code == 0
        ? '完工付'
        : code == 1
        ? '现结'
        : code == 2
        ? '日结'
        : code == 3
        ? '月结'
        : '季付'
    },
  },
  created() {
    if (sessionStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    }
    getById(this.$route.query.id).then((res) => {
      this.jsonData = res.data
    })
  },
  methods: {
    openContactDialog() {
      this.dialogVisible = true
    },
    handleClose(done) {
      this.$confirm('确认结束本次交谈吗？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    toOrders() {
      this.$router.push({
        path: '/needs/takeOrders',
        query: {
          id: this.$route.query.id,
        },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.detail-container
  background #fff
  padding-top 40px
  color #333
  position relative
  .elFooter
    position fixed
    bottom 0
    z-index 9
  .detail
    height auto
  .container
    padding-bottom 40px
    .tile_boxs
      border-bottom 2px solid #dddddd
      position relative
      .titles
        position absolute
        top -30px
        font-size 18px
        font-weight bold
        border-bottom 2px solid #fcb110
        width 72px
        line-height 30px
    .el-row
        margin-bottom 120px
    .title
      font-size 25px
      span
        color #FCB110
    .flex
      display flex
      justify-content space-between
      div
        margin-right 20px
    .project-type
      font-size 14px
      color #999
      margin 10px 0 10px 0
    .left-top
      background #f4f4f4
      padding 20px
      display flex
      flex-wrap wrap
      font-size 16px
      div
        width 50%
        margin-bottom 20px
        color #999
        span
          color #666
      div:last-child
        margin-bottom 0
    .left-content
      margin 40px 0
      .tile_box
        border-bottom 2px solid #dddddd
        position relative
        .title
          position absolute
          top -30px
          font-size 18px
          font-weight bold
          border-bottom 2px solid #fcb110
          width 72px
          line-height 30px
    .btn
      margin-top 40px
      text-align center
      .el-button
        width 150px
      .el-button:nth-child(1)
        background-color #ffffff
        color #fcb110
        border 1px solid #fcb110
      .el-button:nth-child(2)
        background-color #fcb110
        color #fff
        border 1px solid #fcb110
    .right
      font-size 16px
      color #999
      .title
        text-align center
        background #f4f4f4
        line-height 40px
        font-size 18px
      .right-box
        border 1px solid #DDDDDD
        margin auto
        .img
          text-align center
          color #fcb110
          margin 20px 0
          img
            width 50px
            height 50px
            border-radius 50%
        .jindu
          font-size 14px
          margin 20px
        .phone
          border-top 1px solid #ddd
          margin 20px 0
          .pt
            margin-left 20px
            margin-top 10px
          .number
            font-size 20px
            color #fcb110
            text-align center
            margin-top 10px
</style>
