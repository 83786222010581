<template>
    <div class="chart">
        <div class="top">
            <div class="panel"
                v-for="(item, index) in msgList"
                :key="index">
                <div class="item right" v-if="item.type === 'from'">
                    <div class="avatar">
                        <img :src="myAvatar" alt="">
                    </div>
                    <div class="msg">
                        {{ item.message }}
                    </div>
                    
                </div>
                <div class="item left" v-else>
                    <div class="avatar">
                        <img :src="toAvatar" alt="">
                    </div>
                    <div class="msg">
                        {{ item.message }}
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="bottom">
            <el-input
                ref="input"
                type="textarea"
                :rows="5"
                resize="none"
                placeholder="请输入内容"
                v-model="textarea"
                @keyup.enter.native="send">
            
            </el-input>
            <el-button type="primary" size="mini" class="send-btn" @click="send">提交</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            textarea: '',
            msg: {
            pic: 'https://bangimage.cyzone.cn/bangbase/2019/1231/36/99b9688786a3bc87bb3d7d071689ca3e.png?x-oss-process=style/avatar_rctop',
            name: '吴铭',
            lastMessage: '好的，请问您什么时候方便？我给您介绍一下我们的产品。',
            time: '16:35',
            charts: [
                
                {
                    type: 'to',
                    message: '欢迎来到北海春之晓！我们有优质的技术投产项目、方案、专家、需求等科技资源，您需要哪类资源呢？我们还提供技术项目合作、高校合作、区域技术要素市场解决方案等科技服务，请问有什么可以帮助您的呢？'
                }
                
            ]
        },
        }
    },
    computed: {
        toName () {
            return this.msg.name
        },
        toAvatar () {
            return this.msg.pic
        },
        msgList () {
            return this.msg.charts
        },
        myName () {
            return this.$store.state.user.name
        },
        myAvatar () {
            return this.$store.state.user.avatar
        }
    },
    methods: {
        send (e) {
            this.$emit('send', this.textarea)
            e.preventDefault(); // 阻止浏览器默认换行操作
            return false
        },
        initInput () {
            this.textarea = ''
            this.$refs.input.focus()
        }
    }

}
</script>

<style lang="scss" scoped>
    .chart {
        .top {
            height: 400px;
            background: #eaeaea;
            overflow-y: auto;
            .panel {
                .item {
                    display: flex;
                    padding: 10px;
                    .avatar {
                        flex-shrink: 0;
                        width: 30px;
                        height: 30px;
                        border-radius: 10px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .msg {
                        padding: 0 4px;
                        max-width: 60%;
                        border-radius: 6px;
                        font-size: 12px;
                        line-height: 20px;
                    }
                    &.left {
                        flex-direction: row;
                        .msg {
                            color: #333333;
                            background: #ffffff;
                            margin-left: 10px;
                        }
                    }
                    &.right {
                        flex-direction: row-reverse;
                        .msg {
                            color: #333333;
                            background: #9eea6a;
                            margin-right: 10px
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .bottom {
        position: relative;
        .send-btn {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
    }
</style>

<style lang="scss">
    .chart {
        .el-textarea__inner {

            border-radius: 0;
        }
    }
</style>
